<template>
  <div>
    <b-card title="Araç Tescil Tarihi ve Kapanış">
      <b-alert
        v-if="!itemData.is_closed && (userData.id_com_department === '21' && userData.id_com_user_type === '1')"
        show
        variant="info"
        class="m-2"
      >
        <div class="alert-body text-center">
          Satış destek departmanı tarafından tescil bilgisi henüz girilmemiş. 👏
        </div>
      </b-alert>
      <template v-else>
        <b-form-group
          label="Araç Tescil Tarihi"
          label-for="registration_date"
        >
          <b-input-group>
            <b-form-datepicker
              v-model="itemData.registration_date"
              v-bind="{labelNoDateSelected: 'Tescil Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              :disabled="isSaving || itemData.is_closed || (userData.id_com_department !== '21' && userData.id_com_user_type !== '1')"
            />
            <b-input-group-append v-if="!itemData.is_closed && (userData.id_com_department === '21' || userData.id_com_user_type === '1')">
              <b-button
                variant="primary"
                :disabled="isSaving || !itemData.registration_date"
                @click="savingRegistration"
              >
                <feather-icon
                  v-if="!isSaving"
                  icon="SaveIcon"
                />
                <b-spinner
                  v-if="isSaving"
                  small
                />
                Kaydet ve Föyü Kapat
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </template>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BFormGroup, BInputGroup, BFormDatepicker, BButton, BInputGroupAppend, BSpinner, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'SaleOrderFormRegisteryDate',
  components: {
    BCard,
    BFormGroup,
    BInputGroup,
    BFormDatepicker,
    BButton,
    BInputGroupAppend,
    BSpinner,
    BAlert,
  },
  props: {
    callBack: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['saleOrderforms/getSaleOrderForm']
    },
  },
  methods: {
    savingRegistration() {
      this.isSaving = true
      this.$store.dispatch('saleOrderforms/closeSaleOrderForm', {
        id: this.itemData.id,
        registration_date: this.itemData.registration_date,
        order_number: this.itemData.order_number,
        is_closed: 1,
      })
        .then(response => {
          if (response.status) {
            this.isSaving = false
            this.callBack()
          }
        })
    },
  },
}
</script>
